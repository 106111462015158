<style scoped>
.error-box {
  padding-top: 15vh;
  height: 100vh;
  display: flex;
}

.error-box .error-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}

.error-box .error-left .bg-icon {
  background-image: url("../assets/images/icon-404.svg");
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.error-right {
  padding-top: 10vh;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.error-right h1 {
  margin-bottom: 24px;
  color: #515a6e;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
}

.error-right .error-desc {
  margin-bottom: 16px;
  color: #808695;
  font-size: 20px;
  line-height: 28px;
}
</style>

<template>
  <div class="error-box common-card">
    <div class="error-left">
      <div class="bg-icon"></div>
    </div>
    <div class="error-right">
      <h1>404</h1>
      <div class="error-desc">抱歉，访问的页面不存在</div>
      <div>
        <Button type="info" plain @click="goHome">返回首页</Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  methods: {
    goHome() {
      this.$router.replace('/')
    }
  }
};
</script>
